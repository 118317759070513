import blob1 from './blob1.svg';
import blob2 from './blob2.svg';
import blob3 from './blob3.svg';
import blob4 from './blob4.svg';
import blob5 from './blob5.svg';
// import blob6 from './blob6.svg';
// import blob7 from './blob7.svg';
// import blob8 from './blob8.svg';
// import blob9 from './blob9.svg';
// import blob10 from './blob10.svg';
// import blob11 from './blob11.svg';
// import blob12 from './blob12.svg';
// import blob13 from './blob13.svg';
// import blob14 from './blob14.svg';
// import blob15 from './blob15.svg';
// import blob16 from './blob16.svg';

const BlobsSVG4 = {
  blob1,
  blob2,
  blob3,
  blob4,
  blob5,
  // blob6,
  // blob7,
  // blob8,
  // blob9,
  // blob10,
  // blob11,
  // blob12,
  // blob13,
  // blob14,
  // blob15,
  // blob16,
};

export default BlobsSVG4;
