import blob1 from './blob1.svg';
import blob2 from './blob2.svg';
import blob3 from './blob3.svg';
import blob4 from './blob4.svg';
import blob5 from './blob5.svg';
import blob6 from './blob6.svg';
import blob7 from './blob7.svg';
import blob8 from './blob8.svg';
import blob9 from './blob9.svg';
import blob10 from './blob10.svg';

const BlobsSVG3_1 = {
  blob1,
  blob2,
  blob3,
  blob5,
  blob4,
  blob6,
  blob7,
  blob8,
  blob9,
  blob10,
};

export default BlobsSVG3_1;
